import $ from 'jquery';
export default class DisableElementsOnLeadsApiError {
    static $elements = $('.js__disable-on-leads-api-error');
    static init() {
        const isError = $('body').data('leads-api-error');
        if (!this.$elements.length || !isError) {
            return;
        }
        this.$elements.each((index, element) => {
            $(element).addClass('disabled').prop('disabled', true);
        });
    }
}
