import $ from 'jquery';
import IMask from 'imask';
const placeholders = {
    en: {
        d: 'D',
        m: 'М',
        y: 'Y',
    },
    lv: {
        d: 'D',
        m: 'М',
        y: 'G',
    },
    ru: {
        d: 'Д',
        m: 'М',
        y: 'Г',
    },
};
export default class DateInput {
    static init() {
        const $dateInput = $('.js__date-input');
        const locale = $dateInput.data('locale') || 'en';
        const maskOptions = {
            mask: 'dd.mm.YYYY',
            lazy: false,
            overwrite: true,
            autofix: true,
            blocks: {
                dd: { mask: IMask.MaskedRange, placeholderChar: placeholders[locale].d, from: 1, to: 31, maxLength: 2 },
                mm: { mask: IMask.MaskedRange, placeholderChar: placeholders[locale].m, from: 1, to: 12, maxLength: 2 },
                YYYY: { mask: IMask.MaskedRange, placeholderChar: placeholders[locale].y, from: 1900, to: 2999, maxLength: 4 },
            },
        };
        $.each($dateInput, (index, element) => {
            IMask(element, maskOptions);
        });
        this.addEmptyStyle($dateInput);
        $dateInput.on('keyup', () => {
            this.addEmptyStyle($dateInput);
        });
    }
    static isEmptyValue(val) {
        const emptyValue = 'DD.MM.YYYY';
        return val === emptyValue;
    }
    static addEmptyStyle($input) {
        $input.toggleClass('is-empty', this.isEmptyValue($input.val()));
    }
}
