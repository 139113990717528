import $ from 'jquery';
import 'bootstrap/js/dist/tab';
import RevealWhenVisible from '../../components/reveal-when-visible';
import StickyBar from '../../components/sticky-bar';
import DateInput from '../../components/date-input';
import ScrollTo from '../../components/scroll-to';
import CheckOut from '../../components/checkout';
import VideoModalButton from '../../components/video-modal-button';
import RadioInputsBind from '../cart/components/radio-inputs-bind';
import RadioInputDescription from '../cart/components/radio-input-description';
import AddressSelect from '../../components/address-select';
import ProductColorSelect from '../../components/product-color-select';
import DisableElementsOnLeadsApiError from '../cms-content/components/disable-elements-on-leads-api-error';
$(() => {
    VideoModalButton.init();
    RevealWhenVisible.init();
    StickyBar.init();
    DateInput.init();
    ScrollTo.init();
    CheckOut.init();
    RadioInputsBind.init();
    RadioInputDescription.init();
    AddressSelect.init();
    ProductColorSelect.init();
    DisableElementsOnLeadsApiError.init();
});
