import $ from 'jquery';
import { scrollToElement } from '../utils/scroll-to-element';
export default class ScrollTo {
    static $element = $('.js__scroll-to');
    static init() {
        this.$element.on('click', event => {
            const $element = $(event.currentTarget);
            const offset = $element.data('offset') || undefined;
            scrollToElement($($element.data('target')), offset);
        });
    }
}
